import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight5: '#FFFFFF',
      neutralLight4: '#E7D4C8',
      neutralLight3: '#E2CDC0',
      neutralDark3: '#5F5F5B',
      neutralDark2: '#363634',
      neutralDark1: '#000000',
      primaryDark: '#151515',
      primaryLight: '#82181C',
    },
  },
  fontFamily: {
    heading: "'freight-big-pro', sans",
    paragraph: "'baskerville-urw', serif",
    label: "'futura-pt', sans-serif",
  },
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({ children }: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
